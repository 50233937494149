import * as React from 'react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import * as styles from './index.module.scss'

const _iconSize = 24

function PostSocialShare({ siteUrl, slug }: { siteUrl: string; slug: string }) {
  const shareUrl = siteUrl + slug

  return (
    <div className={styles.socialShare}>
      <ul>
        <li className={`${styles.socialShareItem} ${styles.email}`}>
          <EmailShareButton url={shareUrl}>
            <EmailIcon size={_iconSize} round />
          </EmailShareButton>
        </li>
        <li className={`${styles.socialShareItem} ${styles.facebook}`}>
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon size={_iconSize} round />
          </FacebookShareButton>
        </li>
        <li className={`${styles.socialShareItem} ${styles.twitter}`}>
          <TwitterShareButton url={shareUrl}>
            <TwitterIcon size={_iconSize} round />
          </TwitterShareButton>
        </li>
        <li className={`${styles.socialShareItem} ${styles.linkedin}`}>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={_iconSize} round />
          </LinkedinShareButton>
        </li>
        <li className={`${styles.socialShareItem} ${styles.reddit}`}>
          <RedditShareButton url={shareUrl}>
            <RedditIcon size={_iconSize} round />
          </RedditShareButton>
        </li>
        <li className={`${styles.socialShareItem} ${styles.pocket}`}>
          <PocketShareButton url={shareUrl}>
            <PocketIcon size={_iconSize} round />
          </PocketShareButton>
        </li>
      </ul>
    </div>
  )
}

export default PostSocialShare
