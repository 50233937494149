import * as React from 'react'
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'

import * as styles from './index.module.scss'
import PostSeriesItem from '../post-series-item'

interface ISeriesItem {
  slug: string
  title: string
}

interface IProps {
  slug: string
  series: ISeriesItem[]
}

function PostSeries({ slug, series }: IProps) {
  if (series.length < 2) return null

  return (
    <div className={styles.series}>
      <div className={styles.seriesHead}>
        <span className={styles.head}>Post Series</span>
        <div className={styles.iconWrap}>
          <Fa icon={faLayerGroup} />
        </div>
      </div>
      <ul className={styles.seriesList}>
        {series.map((item) => (
          <PostSeriesItem
            item={item}
            isSelected={item.slug === slug}
            key={item.slug}
          />
        ))}
      </ul>
    </div>
  )
}

export default PostSeries
