import { useState, useEffect } from 'react'
import { doc, getDoc, setDoc, getLikesCol } from '../utils/firebase'

export const useFirebaseLikes = (slug: string): [number, () => void] => {
  const likesDocRef = doc(getLikesCol(), slug)

  const [likes, setLikes] = useState(0)

  useEffect(() => {
    getDoc(likesDocRef).then((doc) => {
      const count = doc.data()?.count
      count && setLikes(count)
    })
  }, [])

  const onLikes = () => {
    if (process.env.NODE_ENV === 'development') {
      return setLikes((prev) => Number(prev) + Number(1))
    }

    setLikes((prev) => {
      const next = Number(prev) + Number(1)
      setDoc(likesDocRef, { count: next })
      return next
    })
  }

  return [likes, onLikes]
}
