import * as React from 'react'

import * as styles from './index.module.scss'

function PostComments() {
  const isDevelopment = process.env.NODE_ENV === 'development'

  if (isDevelopment) {
    return (
      <div className={`${styles.comments} ${styles.commentsDev}`}>
        <span>Comments</span>
        <span>displayed when you deploy</span>
      </div>
    )
  }

  return null
}

export default PostComments
