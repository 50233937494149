import { useState, useEffect } from 'react'
import { doc, getDoc, setDoc, getClapsCol } from '../utils/firebase'

export const useFirebaseClaps = (slug: string): [number, () => void] => {
  const clapsDocRef = doc(getClapsCol(), slug)

  const [claps, setClaps] = useState(0)

  useEffect(() => {
    getDoc(clapsDocRef).then((doc) => {
      const count = doc.data()?.count
      count && setClaps(count)
    })
  }, [])

  const onClaps = () => {
    if (process.env.NODE_ENV === 'development') {
      return setClaps((prev) => Number(prev) + Number(1))
    }

    setClaps((prev) => {
      const next = Number(prev) + Number(1)
      setDoc(clapsDocRef, { count: next })
      return next
    })
  }

  return [claps, onClaps]
}
