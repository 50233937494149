import * as React from 'react'

import ClapButton from '../clap-button'
import LikeButton from '../like-button'

import * as styles from './index.module.scss'

function PostReaction({ slug }: { slug: string }) {
  return (
    <div id={styles.postReaction}>
      <ClapButton slug={slug} classNames={styles.clapButton} />
      <LikeButton slug={slug} />
    </div>
  )
}

export default PostReaction
