// extracted by mini-css-extract-plugin
export var headerActiveAnimationTime = ".35s";
export var postContainer = "post-module--post-container--7X8D1";
export var post = "post-module--post--SDz4L";
export var postTitle = "post-module--post-title--rPEv4";
export var postInfo = "post-module--post-info--rm27Z";
export var dateWrap = "post-module--date-wrap--UVBR8";
export var writeDate = "post-module--write-date--cFeQt";
export var updateDate = "post-module--update-date--d5TdR";
export var postContent = "post-module--post-content--n-ilG";
export var postHeader = "post-module--post-header--2mLfQ";
export var postHeaderActive = "post-module--post-header-active--7XX9l";