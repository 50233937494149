import * as React from 'react'
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome'
import { faListUl } from '@fortawesome/free-solid-svg-icons'

import { IToc } from '../../types/toc'

import * as styles from './index.module.scss'

//const PostTocButton = ({ toc, onClick }: { toc: any; onClick: () => void }) => {
function PostTocButton({ toc, onClick }: { toc: IToc; onClick: () => void }) {
  const isTableOfContents = toc.items !== null && toc.items !== undefined
  if (!isTableOfContents) return null

  return (
    <div className={styles.postTocButton}>
      <div
        className={styles.button}
        onClick={onClick}
        onKeyDown={() => void 0}
        role='button'
        tabIndex={0}
      >
        <Fa icon={faListUl} />
      </div>
    </div>
  )
}

export default PostTocButton
