import * as React from 'react'
import { useEffect } from 'react'
import { throttle } from 'lodash'
import scrollToElement from 'scroll-to-element'

import TocWrap from './toc-wrap'

import { IProps } from './types'

import { useScrollEvent } from '../../hooks/use-scroll-event'
import * as Dom from '../../utils/dom'

import * as styles from './index.module.scss'

// const getHighlightHeaders = (highlight?: { target: string }) =>
function getHighlightHeaders(highlight?: { target: string }): HTMLDivElement[] {
  return (
    (highlight &&
      Array.from(
        Dom.getElements(`.${highlight.target} h2, h3, h4, h5, h6`) || []
      )) ||
    []
  )
}

// const onScrollEvent = (headers: HTMLElement[]) => {
function onScrollEvent(headers: HTMLElement[]): void {
  if (headers.length < 1) return

  const scrollPosY = Dom.getBodyScrollTop()
  const windowHeight = Dom.getWindowHeight()

  if (typeof scrollPosY === `undefined` || typeof windowHeight === `undefined`)
    return

  for (const header of headers) {
    if (!!header.id === false) continue // id가 없으면 패스(markdown에서 잘못 적은 것)

    const headerPosY = Dom.getPosYWith(header, { absolute: true })
    const tocLinker = Dom.getElement(
      `a[href='${Dom.getPathname()}#${header.id}']`
    )

    if (
      (scrollPosY as number) >=
      headerPosY - ((windowHeight as number) * 4) / 5
    ) {
      tocLinker && Dom.addClass(tocLinker, styles.active)
    } else {
      tocLinker && Dom.removeClass(tocLinker, styles.active)
    }
  }
}

// const onSmoothScrolling = (headers: HTMLElement[]) => {
function onSmoothScrolling(headers: HTMLElement[]): void {
  if (headers.length < 1) return

  const windowHeight = Dom.getWindowHeight()

  for (const header of headers) {
    const tocLinker = Dom.getElement(
      `a[href='${Dom.getPathname()}#${header.id}']`
    )
    if (!tocLinker) continue

    tocLinker.addEventListener('click', (e) => {
      e.preventDefault()
      scrollToElement(header, {
        offset: -(windowHeight ? (windowHeight * 1) / 5 : 240),
      })
    })
  }
}

// const PostToc: React.FC<IProps> = ({ toc, inside, highlight }) => {
function PostToc({ toc, inside, highlight }: IProps) {
  const isTableOfContents = toc.items !== null && toc.items !== undefined
  if (!isTableOfContents) return null

  // eslint-disable-next-line
  useScrollEvent(throttle(() => onScrollEvent(getHighlightHeaders(highlight))))

  // eslint-disable-next-line
  useEffect(() => onSmoothScrolling(getHighlightHeaders(highlight)), [])

  if (inside) {
    return (
      <div
        className={`${styles.insideTocWrap} ${
          inside.visible ? '' : styles.hidden
        }`}
      >
        <TocWrap toc={toc} inside={inside} />
      </div>
    )
  }

  return <TocWrap toc={toc} inside={inside} />
}

export default PostToc
