import * as React from 'react'

import Toc from '../toc'

import { IProps } from '../types'

import * as styles from '../index.module.scss'

// const TocWrap: React.FC<IProps> = ({ toc, inside }) => {
function TocWrap({ toc, inside }: IProps) {
  return (
    <div className={`${styles.toc} ${inside ? styles.inside : styles.outside}`}>
      <Toc items={toc.items} />
    </div>
  )
}

export default TocWrap
