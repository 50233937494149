import * as React from 'react'
import { useRef, useState, useEffect } from 'react'

import HeartSvg from '-!svg-react-loader!../../svg/heart.svg'
import PawSvg from '-!svg-react-loader!../../svg/paw.svg'
import PawClapSvg from '-!svg-react-loader!../../svg/paw-clap.svg'

import { useFirebaseLikes } from '../../hooks/use-firebase-likes'

import * as styles from './index.module.scss'
import { range } from 'lodash'

function LikeButton({
  slug,
  classNames = '',
}: {
  slug: string
  classNames?: string
}) {
  const buttonRef = useRef<HTMLDivElement>(null)

  const [doingLike, setDoingLike] = useState(false) // doing animation
  const [isLiked, setLiked] = useState(false) // active
  const [wasSetServerLikes, setWasServerLikes] = useState(false) // up(send) to server
  const [likes, setLikes] = useState(0)
  const [serverLikes, setServerLikes] = useFirebaseLikes(slug)

  const confettiAmount = 60
  const confettiColors = [
    '#7d32f5',
    '#f6e434',
    '#63fdf1',
    '#e672da',
    '#295dfe',
    '#6e57ff',
  ]

  const random = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1) + min)

  const createConfetti = (to: HTMLElement) => {
    const elem = document.createElement('i')
    elem.style.setProperty('--x', `${random(-260, 260)}px`)
    elem.style.setProperty('--y', `${random(-160, 160)}px`)
    elem.style.setProperty('--r', `${random(0, 360)}deg`)
    elem.style.setProperty('--s', `${random(0.6, 1)}`)
    elem.style.setProperty('--b', confettiColors[random(0, 5)])
    to.appendChild(elem)
  }

  const doLike = () => {
    buttonRef.current?.classList.add(styles.animation)
    range(0, confettiAmount).forEach(() => {
      buttonRef.current && createConfetti(buttonRef.current)
    })

    setTimeout(() => {
      buttonRef.current?.classList.add(styles.confetti)
      setTimeout(() => {
        buttonRef.current?.classList.add(styles.liked)
        if (wasSetServerLikes) {
          setLikes((prev) => Number(prev) + Number(1))
        } else {
          setWasServerLikes(true)
          setServerLikes()
        }
        setLiked(true)
        setDoingLike(false)
      }, 400)
      setTimeout(() => {
        buttonRef.current?.querySelectorAll('i').forEach((i) => i.remove())
      }, 600)
    }, 260)
  }

  const undoLike = () => {
    buttonRef.current?.classList.remove(
      styles.animation,
      styles.liked,
      styles.confetti
    )
    setLikes((prev) => prev - 1)
    setLiked(false)
    setDoingLike(false)
  }

  const onClick = () => {
    if (doingLike) return

    setDoingLike(true)
    if (isLiked) {
      undoLike()
    } else {
      doLike()
    }
  }

  useEffect(() => {
    setLikes(serverLikes)
  }, [serverLikes])

  return (
    <div
      className={`${styles.pawButton} ${classNames}`}
      onClick={onClick}
      onKeyDown={() => void 0}
      role='button'
      ref={buttonRef}
      tabIndex={0}
    >
      <div className={styles.text}>
        <HeartSvg />
        <span>Like</span>
      </div>
      <span>{likes}</span>
      <div className={styles.paws}>
        <PawSvg className={styles.paw} />
        <div className={styles.pawEffect}>
          <div />
        </div>
        <PawClapSvg className={styles.pawClap} />
      </div>
    </div>
  )
}

export default LikeButton
