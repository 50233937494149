import * as React from 'react'
import PostTagListItem from '../post-tag-list-item'

import * as styles from './index.module.scss'

interface IProps {
  tags: string[]
}

function PostTagList({ tags }: IProps) {
  if (!tags.length || typeof tags[0] === `undefined`) return null

  return (
    <ul className={styles.postTagList}>
      {tags.map((tag: string) => (
        <PostTagListItem tag={tag} key={tag} />
      ))}
    </ul>
  )
}

export default PostTagList
