import * as React from 'react'
import { Link } from 'gatsby'

import { ITocItem } from '../types'

// const Toc = ({ items }: { items: ITocItem[] }) => {
function Toc({ items }: { items: ITocItem[] }) {
  return (
    <ul>
      {items.map((item) => {
        if (typeof item.url === `undefined`) return null

        const innerItems = item.items
        const hasInnerItems = innerItems !== null && innerItems !== undefined

        return (
          <li key={item.url}>
            <Link to={item.url}>{item.title}</Link>
            {hasInnerItems && <Toc items={innerItems} />}
          </li>
        )
      })}
    </ul>
  )
}

export default Toc
