import * as React from 'react'

export default function mojsInit(
  clapButtonRef: React.RefObject<HTMLButtonElement>,
  clapCountRef: React.RefObject<HTMLDivElement>,
  clapTotalCountRef: React.RefObject<HTMLDivElement>,
  count: number,
  isUnclicked: boolean,
  // eslint-disable-next-line
  setAnimationTimeline: React.Dispatch<any>
): void {
  // eslint-disable-next-line
  const mojs = require('@mojs/core')
  const tlDuration = 300
  const triangleBurst = new mojs.Burst({
    parent: clapButtonRef.current,
    radius: { 50: 95 },
    count: 5,
    angle: 30,
    children: {
      shape: 'equal',
      radius: { 10: 0 },
      scale: 1,
      stroke: 'rgba(211,84,0 ,0.5)',
      strokeWidth: 2,
      angle: 210,
      delay: 30,
      speed: 0.2,
      easing: mojs.easing.bezier(0.1, 1, 0.3, 1),
      duration: tlDuration,
    },
  })

  const circleBurst = new mojs.Burst({
    parent: clapButtonRef.current,
    radius: { 50: 75 },
    angle: 25,
    duration: tlDuration,
    children: {
      shape: 'circle',
      fill: 'rgba(149,165,166 ,0.5)',
      delay: 30,
      speed: 0.2,
      radius: { 3: 0 },
      easing: mojs.easing.bezier(0.1, 1, 0.3, 1),
    },
  })

  const countAnimation = new mojs.Html({
    el: clapCountRef.current,
    isShowStart: false,
    isShowEnd: true,
    y: { 0: -30 },
    opacity: { 0: 1 },
    duration: tlDuration,
  }).then({
    opacity: { 1: 0 },
    y: -80,
    delay: tlDuration / 2,
  })

  const opacityStart = count > 0 && isUnclicked ? 1 : 0

  const countTotalAnimation = new mojs.Html({
    el: clapTotalCountRef.current,
    isShowStart: false,
    isShowEnd: true,
    opacity: { [opacityStart]: 1 },
    delay: (3 * tlDuration) / 2,
    duration: tlDuration,
    y: { 0: -3 },
  })

  const scaleButton = new mojs.Html({
    el: clapButtonRef.current,
    duration: tlDuration,
    scale: { 1.3: 1 },
    easing: mojs.easing.out,
  })

  const clap = clapButtonRef.current
  clap && (clap.style.transform = 'scale(1, 1)')

  const animTimeline = new mojs.Timeline()
  animTimeline.add([
    countAnimation,
    countTotalAnimation,
    scaleButton,
    circleBurst,
    triangleBurst,
  ])
  setAnimationTimeline(animTimeline)
}
