import * as React from 'react'
import { useRef, useState, useEffect } from 'react'

import HandLeft from '-!svg-react-loader!../../svg/hand-left.svg'
import HandRight from '-!svg-react-loader!../../svg/hand-right.svg'

import { useFirebaseClaps } from '../../hooks/use-firebase-claps'
import { isMobile } from '../../utils/mobile-manager'

import mojsInit from './mojs-init'

import * as styles from './index.module.scss'

function ClapButton({
  slug,
  classNames = '',
}: {
  slug: string
  classNames?: string
}) {
  const clapButtonRef = useRef<HTMLButtonElement>(null)
  const clapCountRef = useRef<HTMLDivElement>(null)
  const clapTotalCountRef = useRef<HTMLDivElement>(null)
  const clapHandLeft = useRef<HTMLDivElement>(null)
  const clapHandRight = useRef<HTMLDivElement>(null)

  const [isUnclicked, setUnclicked] = useState(true)
  const [isClapped, setClapped] = useState(false)
  const [claps, setClaps] = useState(0)
  const [serverClaps, setServerClaps] = useFirebaseClaps(slug)

  // eslint-disable-next-line
  const [animationTimeline, setAnimationTimeline] = useState<any>(null)

  function onClaps() {
    if (!animationTimeline) return

    animationTimeline.replay()
    setClaps((prev) => Number(prev) + Number(1))
    setServerClaps()
    setUnclicked(false)
    setClapped(true)
  }

  function onMouseOverClapHands() {
    if (isMobile()) return
    clapHandLeft.current?.classList.add(styles.hover)
    clapHandRight.current?.classList.add(styles.hover)
  }

  function onMouseLeaveClapHands() {
    if (isMobile()) return
    clapHandLeft.current?.classList.remove(styles.hover)
    clapHandRight.current?.classList.remove(styles.hover)
    clapHandLeft.current?.classList.remove(styles.clapping)
    clapHandRight.current?.classList.remove(styles.clapping)
  }

  function onMouseDownClapHands() {
    if (isMobile()) return
    clapHandLeft.current?.classList.remove(styles.hover)
    clapHandRight.current?.classList.remove(styles.hover)
    clapHandLeft.current?.classList.add(styles.clapping)
    clapHandRight.current?.classList.add(styles.clapping)
  }

  function onMouseUpClapHands() {
    if (isMobile()) return
    clapHandLeft.current?.classList.remove(styles.clapping)
    clapHandRight.current?.classList.remove(styles.clapping)
    clapHandLeft.current?.classList.add(styles.hover)
    clapHandRight.current?.classList.add(styles.hover)
  }

  function onTouchStart() {
    if (!isMobile()) return
    clapHandLeft.current?.classList.remove(styles.hover)
    clapHandRight.current?.classList.remove(styles.hover)
    clapHandLeft.current?.classList.add(styles.clapping)
    clapHandRight.current?.classList.add(styles.clapping)
  }

  function onTouchEnd() {
    if (!isMobile()) return
    clapHandLeft.current?.classList.remove(styles.hover)
    clapHandRight.current?.classList.remove(styles.hover)
    clapHandLeft.current?.classList.remove(styles.clapping)
    clapHandRight.current?.classList.remove(styles.clapping)
  }

  useEffect(() => {
    mojsInit(
      clapButtonRef,
      clapCountRef,
      clapTotalCountRef,
      claps,
      isUnclicked,
      setAnimationTimeline
    )
  }, [])

  return (
    <div className={`${styles.claps} ${classNames}`}>
      <div className={styles.buttonWrap}>
        <button
          type='button'
          className={isClapped ? styles.active : ''}
          onClick={onClaps}
          onMouseOver={onMouseOverClapHands}
          onMouseOut={onMouseLeaveClapHands}
          onFocus={onMouseOverClapHands}
          onBlur={onMouseLeaveClapHands}
          onMouseDown={onMouseDownClapHands}
          onMouseUp={onMouseUpClapHands}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          ref={clapButtonRef}
        >
          <div className={styles.clapHands}>
            <div
              className={`${styles.clapHand} ${styles.handLeft}`}
              ref={clapHandLeft}
            >
              <HandLeft />
            </div>
            <div
              className={`${styles.clapHand} ${styles.handRight}`}
              ref={clapHandRight}
            >
              <HandRight />
            </div>
          </div>
          <div className={styles.count} ref={clapCountRef}>
            +{claps}
          </div>
          <div className={styles.countTotal} ref={clapTotalCountRef}>
            {serverClaps.toLocaleString()}
          </div>
        </button>
      </div>
    </div>
  )
}

export default ClapButton
