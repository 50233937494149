import * as React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

import * as styles from './index.module.scss'

interface SeriesItem {
  slug: string
  title: string
}

interface Props {
  item: SeriesItem
  isSelected: boolean
}

// const PostSeriesItem: React.FC<Props> = ({ item, isSelected }) => {
function PostSeriesItem({ item, isSelected }: Props) {
  return (
    <li
      className={`${styles.seriesItem} ${
        isSelected ? styles.currentSeries : ''
      }`}
    >
      <Link to={item.slug}>
        <span>{item.title}</span>
        <div className={styles.iconWrap}>
          {isSelected ? <Fa icon={faAngleLeft} /> : null}
        </div>
      </Link>
    </li>
  )
}

export default PostSeriesItem
