// extracted by mini-css-extract-plugin
export var claps = "index-module--claps--rJ6kA";
export var buttonWrap = "index-module--button-wrap--+qZy7";
export var active = "index-module--active--dEKlQ";
export var shockwave = "index-module--shockwave--KX4YJ";
export var clapHands = "index-module--clap-hands--7LVKb";
export var clapHand = "index-module--clap-hand--LEdhT";
export var handLeft = "index-module--hand-left--mz7AF";
export var hover = "index-module--hover--EuOyH";
export var clapping = "index-module--clapping--lGTa1";
export var handRight = "index-module--hand-right--vnlHO";
export var count = "index-module--count--Yf5af";
export var countTotal = "index-module--count-total--qDi46";