import { Adsense } from '@ctrl/react-adsense'
import * as React from 'react'
import { sponsor } from '../../../gatsby-meta-config'
import * as styles from './index.module.scss'

function PostAd() {
  const isDevelopment = process.env.NODE_ENV === 'development'

  if (isDevelopment) {
    return (
      <aside className={`${styles.ad} ${styles.adDev}`}>
        <span>Ads</span>
        <span>displayed when you deploy</span>
      </aside>
    )
  }

  const { client, slot } = sponsor.googleAdsense
  const { postAd } = slot

  return (
    <aside className={styles.ad}>
      {client && postAd && (
        <Adsense
          client={client}
          slot={postAd}
          style={{ display: 'block' }}
          format='auto'
          responsive='true'
        />
      )}
    </aside>
  )
}

export default PostAd
