// https://firebase.google.com/docs/firestore/quickstart#web-version-9
import 'firebase/analytics'
import { FirebaseApp, initializeApp } from 'firebase/app'
import 'firebase/auth'
import {
  collection,
  CollectionReference,
  DocumentData,
  getFirestore,
} from 'firebase/firestore/lite'
import { Claps } from '../types/firestore/claps'
import { Likes } from '../types/firestore/likes'

// eslint-disable-next-line
let firebaseApp: FirebaseApp | undefined
const getFirebaseApp = () => {
  if (firebaseApp) return firebaseApp

  firebaseApp = initializeApp({
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
  })
}

export { doc, getDoc, setDoc } from 'firebase/firestore/lite'

const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(
    getFirestore(getFirebaseApp()),
    collectionName
  ) as CollectionReference<T>
}

export const getClapsCol = (): CollectionReference<Claps> =>
  createCollection<Claps>('claps')
export const getLikesCol = (): CollectionReference<Likes> =>
  createCollection<Likes>('likes')
