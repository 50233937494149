import * as React from 'react'
import { Link } from 'gatsby'

import * as styles from './index.module.scss'

function PostTagListItem({ tag }: { tag: string }) {
  return (
    <li key={tag} className={styles.postTag}>
      <Link to={`/tags#${tag}`}>{`#${tag}`}</Link>
    </li>
  )
}

export default PostTagListItem
