import { graphql, PageProps } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import 'katex/dist/katex.min.css'
import { throttle } from 'lodash'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import PostAd from '../components/post-ad'
import PostComments from '../components/post-comments'
import PostReaction from '../components/post-reaction'
import PostSeries from '../components/post-series'
import PostSocialShare from '../components/post-social-share'
import PostTagList from '../components/post-tag-list'
import PostToc from '../components/post-toc'
import PostTocButton from '../components/post-toc-button'
import SEO from '../components/seo'
import Layout from '../layout'
import '../styles/code.scss'
import { setCustomElements } from '../utils/custom-elements' // add custom html elements (custom tags)
import * as Dom from '../utils/dom'
import * as styles from './post.module.scss'
import { IProps } from './types'

const enterObserverEvent = throttle(
  ([{ isIntersecting, target: header }]: IntersectionObserverEntry[]) => {
    if (isIntersecting) {
      // set active
      header.setAttribute('data-text', ``)
      Dom.addClass(header, styles.postHeaderActive)
      setTimeout(() => {
        Dom.hasClass(header, styles.postHeaderActive) &&
          header.setAttribute(
            'data-text',
            `${(header as HTMLElement).innerText}`
          )
      }, parseFloat(styles.headerActiveAnimationTime) * 1000)
    }
  }
)

const exitObserverEvent = throttle(([{ isIntersecting, target: header }]) => {
  if (!isIntersecting) {
    // set in-active
    Dom.removeClass(header, styles.postHeaderActive)
    header.setAttribute('data-text', ``)
  }
})

function Post({ data, pageContext }: IProps & PageProps) {
  const [showInsideToc, setShowInsideToc] = useState(false)

  const {
    // title: siteTitle,
    // comment: { disqusShortName, utterances },
    // sponsor: { buyMeACoffeeId },
    siteUrl,
    keywords,
    // author,
    // sponsor,
    // share: { revueId },
  } = data.site.siteMetadata
  const { frontmatter, body, tableOfContents, fields, excerpt } = data.mdx
  const { title: postTitle, date, update, tags, thumbnail } = frontmatter
  const { slug } = fields
  const { series } = pageContext

  const thumbnailSrcString =
    thumbnail?.childImageSharp.fixed?.src ??
    thumbnail?.childImageSharp.gatsbyImageData.images.fallback?.src ??
    undefined

  const thumbnailSrc = thumbnailSrcString
    ? // eslint-disable-next-line
      `${siteUrl}${thumbnailSrcString}`
    : undefined

  const metaKeywords = useCallback(
    (keywordList: string[], tagList: string[]): string[] => {
      const resultKeywords = new Set<string>()
      for (const v of [...keywordList, ...tagList]) resultKeywords.add(v)

      return Array.from(resultKeywords)
    },
    []
  )

  useEffect(() => {
    setCustomElements()

    const windowHeight = Dom.getWindowHeight() || 0
    const headers = Array.from(
      Dom.getElements(`.${styles.postContent} h2, h3, h4, h5, h6`) || []
    )

    const twoObservers = headers.map((header) => {
      Dom.addClass(header, styles.postHeader) // active 의 배경 애니메이션 효과를 위해 덧붙임

      const enterOptions = {
        rootMargin: `-${(windowHeight * 1) / 5}px`,
        threshold: 0.1,
      }
      const exitOptions = {
        rootMargin: `0px`,
        threshold: 0.1,
      }

      const enterObserver = new IntersectionObserver(
        enterObserverEvent,
        enterOptions
      )
      enterObserver.observe(header)

      const exitObserver = new IntersectionObserver(
        exitObserverEvent,
        exitOptions
      )
      exitObserver.observe(header)

      return [enterObserver, exitObserver]
    })

    return () => {
      twoObservers.forEach((observers) => {
        observers.forEach((observer) => observer.disconnect())
      })
    }
  }, [])

  const onClickToc = useCallback(
    () => setShowInsideToc((prev: boolean) => !prev),
    []
  )

  function getUpdatedSpan() {
    if (!update) return

    const updateString: string = update

    return (
      <>
        <span>(</span>
        <span
          className={styles.updateDate}
        >{`Last updated: ${updateString}`}</span>
        <span>)</span>
      </>
    )
  }

  return (
    <Layout>
      <SEO
        title={postTitle}
        description={excerpt}
        thumbnail={thumbnailSrc}
        keywords={metaKeywords(keywords, tags)}
        pubData={{ date, update }}
      />

      <div className={styles.postContainer}>
        <PostToc
          toc={tableOfContents}
          highlight={{ target: styles.postContent }}
        />

        <div className={styles.post}>
          <h1 className={styles.postTitle}>{postTitle}</h1>

          <div className={styles.postInfo}>
            <div className={styles.dateWrap}>
              <span className={styles.writeDate}>{date}</span>
              {getUpdatedSpan()}
            </div>

            <PostTagList tags={tags} />

            <PostTocButton toc={tableOfContents} onClick={onClickToc} />
          </div>

          <PostToc toc={tableOfContents} inside={{ visible: showInsideToc }} />

          <PostSeries slug={slug} series={series} />

          <div className={styles.postContent}>
            <MDXRenderer>{body}</MDXRenderer>
          </div>
        </div>

        <PostReaction slug={slug} />
        <PostSocialShare siteUrl={siteUrl} slug={slug} />
        <PostAd />
        <PostComments />
      </div>
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostBySlug($slug: String) {
    site {
      siteMetadata {
        title
        author
        siteUrl
        keywords
        comment {
          disqusShortName
          utterances
        }
        sponsor {
          buyMeACoffeeId
        }
        share {
          revueId
        }
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt(truncate: true)
      tableOfContents
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        update(formatString: "MMMM DD, YYYY")
        tags
        thumbnail {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`

export default Post
